nav {
    a {
        color: #FFFFFF;

        &:hover{
          color: #ddd;  
        }
    }
}

.navbar-brand {
  //padding: 10px 20px;
  width: 95%;
}

.nav-link {
  color: #FFFFFF !important;

  &:hover,
  &:focus {
    color: #FFFFFF !important;
  }
}
